import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Logger } from '../logger.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private router: Router, private logger: Logger) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.isAuthenticated()) {
      const accessToken = this.auth.getAccessToken();
      const copiedReq = request.clone({
        headers: request.headers.append('Authorization', 'Bearer ' + accessToken).append('Accept-Language', 'hr')
      });
      return next.handle(copiedReq);
    } else {
      this.logger.warn('User is not authenticated. Redirecting to login page');
      this.auth.login();
    }
  }
}
