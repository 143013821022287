import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from './local-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  // Observable navItem source
  private authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this.authNavStatusSource.asObservable();

  private manager = new UserManager(this.getClientSettings());
  private user: User | null;

  constructor(private localStorage: LocalStorage) {
    this.manager.getUser().then(user => {
      this.user = user;
      this.authNavStatusSource.next(this.isAuthenticated());
    });
  }

  login() {
    return this.manager.signinRedirect();
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    this.authNavStatusSource.next(this.isAuthenticated());
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  get authorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  get name(): string {
    return this.user != null ? this.user.profile.name : '';
  }

  signout() {
    this.manager.signoutRedirect();
  }

  get userData(): User {
    return this.user;
  }

  getAccessToken() {
    return this.user.access_token;
  }


  private getClientSettings(): UserManagerSettings {
    return {
      authority: environment.identityApi,
      client_id: 'reportingClient',
      redirect_uri: `${window.location.origin}/loading`,
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      scope: 'openid profile reportingAPI',
      filterProtocolClaims: true,
      loadUserInfo: true,
      // stateStore: this.localStorage,
      // userStore: this.localStorage,
      automaticSilentRenew: true,
      response_mode: 'query',
      silent_redirect_uri: `${window.location.origin}/silent-refresh.html`
    };
  }
}
