import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'p365-rep-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  isExpanded = false;
  helpUrl: string;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.helpUrl = environment.helpUrl;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    console.log('logout');
    this.authService.signout();
  }
}
