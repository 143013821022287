import { Injectable } from '@angular/core';
import { ReportDetails, ReportListQueryReponse } from '../models/report-list-query-reponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportingAPiBase } from './reporting-api-base.service';

@Injectable({ providedIn: 'root' })
export class ReportService extends ReportingAPiBase {

  constructor(private http: HttpClient) {
    super();
  }

  public getReports(): Observable<ReportListQueryReponse> {
    return this.http.get<ReportListQueryReponse>(`${this.baseUrl}/api/reportDefinitions`);
  }

}
