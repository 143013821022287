import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Logger {
  constructor() {

  }

  public info(msg: string, ...params: any[]): void {
    // tslint:disable-next-line:no-console
    console.info(msg, params);
  }

  public debug(msg: string, ...params: any[]): void {
    console.log(msg, params);
  }

  public warn(msg: string, ...params: any[]): void {
    console.warn(msg, params);
  }

  public error(msg: string, ...params: any[]): void {
    console.error(msg, params);
  }
}
