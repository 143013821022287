import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'p365-rep-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public logoUrl = environment.examApi + '/api/logo';

    constructor(private authService: AuthService) { }

    ngOnInit() {
    }

    login() {
        this.authService.login();
    }
}
