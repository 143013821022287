import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {environment } from '../../../environments/environment';

@Component({
  selector: 'p365-rep-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    public logoUrl = environment.examApi + '/api/logo';

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.completeAuthentication();
    this.authService.authNavStatus$.subscribe((status: boolean) => {
      if (status) {
        console.log('Status is true', this.authService.userData);
        this.router.navigate(['/portal', 'reports']);
      } else {
        console.log('Status id false');
      }
    }, (error) => {
      console.error('Error while confirming login!', error);
    });
  }

}
