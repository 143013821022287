import { StateStore } from 'oidc-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage implements StateStore {
  set(key: string, value: any): Promise<void> {
    localStorage.setItem(key, value);
    return Promise.resolve();
  }
  get(key: string): Promise<any> {
    const value = localStorage.getItem(key);
    return Promise.resolve(value);
  }
  remove(key: string): Promise<any> {
    localStorage.removeItem(key);
    return Promise.resolve();
  }
  getAllKeys(): Promise<string[]> {
    const keys = Object.keys(localStorage);
    return Promise.resolve(keys);
  }
}
